import styled from 'styled-components'

export const Header = styled.div`
  position: absolute;

  & > div {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
  }

  top: 0;
  min-height: 1rem;
  width: 100%;
  background: #ffffff5c;
  z-index: 9;
`

export const HeaderTitle = styled.h2`
  position: relative;
  color: white;
  z-index: 9;
  padding-top: 25vh;
  font-size: 4.5rem;
  text-align: center;
  letter-spacing: 0.2rem;
  font-family: 'Londrina Solid', cursive;
  text-shadow: 2px 5px 0px black;
  transform: rotate(8deg);
  font-weight: 700;
  z-index: 0;

  @media (max-width: 700px) {
    font-size: 2rem;
    padding-top: 15vh;
    text-shadow: 1px 1px 1px black;
  }
`
