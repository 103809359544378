import styled from 'styled-components'

export const MainHead = styled.div`
  position: relative;
  background: url('./cover.jpg');
  background-position: bottom center, top center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  padding-bottom: 2rem;
  @media (max-width: 700px) {
    min-height: 70vh;
  }
`
