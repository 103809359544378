import React from 'react'
import styled from 'styled-components'

export const MainContact = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 4rem;

	& > h2.line {
		position: relative;
		text-align: center;
		width: 90%;
		color: grey;
		font-size: 1.5rem;
		font-weight: 500;
		letter-spacing: 0.2rem;
		color: black;
		text-transform: uppercase;
		@media (max-width: 700px) {
			display: none;
		}

		&:before,
		:after {
			content: '';
			position: absolute;
			height: 1px;
			width: 35%;
			border: 1px solid #00000026;
			top: 50%;
			bottom: auto;
			left: auto;
			right: auto;
		}
		&:before {
			left: 0rem;
		}
		&:after {
			right: 0rem;
		}
	}
	& > div {
		display: flex;
		width: 100%;
		max-width: 1000px;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap-reverse;
		& > div,
		form {
			flex-grow: 1;
		}
	}
`

export const Form1 = styled(({ className, children }) => (
	<form className={className}>{children}</form>
))`
	display: none;
	visability: hidden;
	padding: 1rem;

	& input,
	textarea {
		display: block;
		padding: 0.6rem;
		min-width: 320px;
		border: 1px solid lightgrey;
		font-weight: 400;
	}
	& > label {
		width: auto;
		display: block;
		margin-bottom: 1rem;
		padding: 0.6rem;
		font-size: 0.7rem;
		font-weight: bold;
		text-transform: uppercase;
		color: grey;
		input {
			border: none;
			font-weight: 400;
			font-size: 1rem;
			border: 1px solid lightgrey;
			border-radius: 0.2rem;
		}
	}
`
