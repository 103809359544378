import styled from "styled-components"

export const MainGallery = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 3rem 0;
  margin-bottom: 4rem;

  /*background: url("data:image/svg+xml,%0A%3Csvg width='1440px' height='389px' viewBox='0 0 1440 389' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3ClinearGradient x1='50%25' y1='0%25' x2='50%25' y2='100%25' id='linearGradient-1'%3E%3Cstop stop-color='%234EC7E8' offset='0%25'%3E%3C/stop%3E%3Cstop stop-color='%233A98C0' offset='100%25'%3E%3C/stop%3E%3C/linearGradient%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Desktop-HD' transform='translate(0.000000, -1248.000000)' fill='url(%23linearGradient-1)'%3E%3Cpolygon id='Path-3' points='0 1558 0 1387 343 1307 843 1387 1440 1248 1440 1637'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
*/
  background-repeat: no-repeat;
  background-size: 100vw;
  background-position: center bottom;

  & > img {
    display: inline-block;
    flex-shrink: 1;
    max-width: 20%;
  }
`
