import styled from 'styled-components'

export const MainPara = styled.p`
  max-width: 800px;
  text-align: left;
  margin: 1rem auto;
  font-size: 1.2rem;
  line-height: 1.7rem;
  letter-spacing: 0.1rem;
  padding: 1.2rem;
  font-weight: 400;
  white-space: pre-wrap;
  & > b {
    font-family: 'Londrina Solid', cursive;
    font-size: 1.3rem;
  }
`
