import React, { useContext } from 'react'
import './App.css'
import { LanguageContext, TranslationsContext } from './context/store.js'
import {
  Header,
  MainHead,
  LanguageBar,
  HeaderTitle,
  MainPara,
  InfoBox,
  MainContact,
  Button,
  MapsSection,
  Form1,
  Social,
  LayoutMain,
} from './components'

function App() {
  const t = useContext(TranslationsContext)
  const [lang, setLang] = useContext(LanguageContext)

  return (
    <LayoutMain>
      <MainHead>
        <Header>
          <div>
            <img
              src='./logo.png'
              alt='logo'
              style={{ height: '2rem', margin: '1rem' }}
            />
            <LanguageBar lang={['no', 'en', 'de']} setLang={setLang} />
          </div>
        </Header>
        <HeaderTitle>{t.mainHead[lang]}</HeaderTitle>
        <InfoBox
          data={{
            price: {
              sum: '300',
              desc: '/' + t.infoBox[lang].night,
              currency: 'NOK',
            },
            price2: {
              sum: '250',
              desc: '/' + t.infoBox[lang].night,
              currency: 'NOK',
            },
            facilities: [
              { name: t.infoBox[lang].el, icon: 'bolt' },
              { name: t.infoBox[lang].water, icon: 'tint' },
            ],
            services: [
              { name: t.infoBox[lang].boat, icon: 'boat' },
              { name: t.infoBox[lang].elboat, icon: 'boat' },
            ],
          }}
        />
      </MainHead>
      <div>
        <MainPara>
          <b>{t.mainPara[lang].paraHead}</b> <br />
          {t.mainPara[lang].paragraph}
        </MainPara>
      </div>

      <MapsSection>
        <a
          href='https://www.google.com/maps/place/Ølvesvegen+211,+5635+Hatlestrand,+Norway/@60.0273397,5.8427735,17z/data=!3m1!4b1!4m5!3m4!1s0x463c6bb653dd854d:0xccc4dfca11bd14ad!8m2!3d60.0273397!4d5.8449622'
          target='_blank'
          rel='noopener noreferrer'
          style={{
            height: '100%',
            maxWidth: '900px',
            padding: '1rem',
            display: 'block',
            margin: '0 auto',
          }}
        >
          <iframe
            title='map'
            style={{ pointerEvents: 'none', marginBottom: '-.5rem' }}
            width='100%'
            height='400'
            frameBorder='0'
            src='https://www.openstreetmap.org/export/embed.html?bbox=5.837184190750123%2C60.02498875524373%2C5.851346254348756%2C60.029764495499776&amp;layer=mapnik'
          />
        </a>
        <br />
        <small style={{ letterSpacing: '0.1rem' }}>
          <address>Olvesvegen 211, 5635 Hatlestrand, Kvinnherad</address>
        </small>
        <Button
          style={{ margin: '1rem auto' }}
          onClick={(_) =>
            window.open(
              'https://www.google.com/maps/place/Ølvesvegen+211,+5635+Hatlestrand,+Norway/@60.0273397,5.8427735,17z/data=!3m1!4b1!4m5!3m4!1s0x463c6bb653dd854d:0xccc4dfca11bd14ad!8m2!3d60.0273397!4d5.8449622',
              '_blank'
            )
          }
        >
          {t.map[lang]}
        </Button>
      </MapsSection>
      <MainContact>
        <h2 className='line'>{t.contact[lang].header}</h2>

        <div>
          <Form1>
            <label>
              {t.contact[lang].form.name}
              <input type='text' />
            </label>

            <label>
              {t.contact[lang].form.email}
              <input type='email' />
            </label>
            <label>
              {t.contact[lang].form.message}
              <textarea />
            </label>
            <div style={{ padding: '.6rem' }}>
              <Button type='button' style={{ width: '8rem' }}>
                Send
              </Button>
            </div>
          </Form1>
          <div style={{ textAlign: 'center' }}>
            <Social>
              <h3>{t.contact[lang].social}</h3>
              <div className='social-btns'>
                {/* <a
                  href='https://m.me/101695621256202'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ background: 'url(/messanger.png)' }}
                /> */}
                <a
                  href='https://www.facebook.com/bobilparkeringen'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ background: 'url(/fb.png)' }}
                />
                <a
                  href='mailto:ingemar.trine@gmail.com'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ background: 'url(/email.png)' }}
                />
                <a
                  href='https://www.instagram.com/royranecamping/'
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ background: 'url(/instagram.png)' }}
                />
              </div>
              <hr />
              <h3>{t.contact[lang].call}</h3>
              <a href='tel:+4740407354'>+47 40 40 73 54</a>
            </Social>
          </div>
        </div>
      </MainContact>
    </LayoutMain>
  )
}

export default App
