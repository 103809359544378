import styled from 'styled-components'

export const Button = styled.button`
	display: block;
	border: 1px solid grey;
	border-radius: 0.1rem;
	background: none;
	outline: none;
	user-select: none;
	padding: 0.5rem;
	cursor: pointer;
	font-size: 0.8rem;
	color: teal;
	&:hover {
		border-color: black;
	}
`

export const LayoutMain = styled.div`
	height: '100vh';
`
