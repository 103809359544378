import React from 'react'
import styled from 'styled-components'

export const LanguageBar = styled(({ className, children, lang, setLang }) => {
	const options = lang.map(
		x =>
			x !== 'de' && (
				<li key={x} onClick={_ => setLang(x)}>
					<img src={'/' + x + '.png'} alt={x} />
				</li>
			)
	)
	return (
		<div>
			<ul className={className}>
				{options}
				{children}
			</ul>
		</div>
	)
})`
	display: flex;
	min-width: 5rem;
	justify-content: space-evenly;
	padding-top: 0.5rem;
	margin-right: 2rem;
	z-index: 9;
	& > li {
		color: red;
		list-style: none;
		display: block;
		margin-right: 1rem;
		cursor: pointer;
		&:last-child {
			margin-right: 0;
		}
	}
`
