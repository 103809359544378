import styled from "styled-components"

export const MapsSection = styled.div`
  margin: 3rem 0;
  text-align: center;
  & > a > iframe {
    @media (max-width: 700px) {
      height: 200px;
    }
  }
`

// https://www.google.com/maps/place/Ølvesvegen+211,+5635+Hatlestrand,+Norway/@60.0273397,5.8427735,17z/data=!3m1!4b1!4m5!3m4!1s0x463c6bb653dd854d:0xccc4dfca11bd14ad!8m2!3d60.0273397!4d5.8449622
