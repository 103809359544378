import React, { createContext, useState } from "react"
import { translation } from "./language.js"
export const LanguageContext = createContext(null)
export const TranslationsContext = createContext(translation)

const Store = ({ children }) => {
  const [lang, setLang] = useState("no")

  return (
    <LanguageContext.Provider value={[lang, setLang]}>
      <TranslationsContext.Provider value={translation}>
        {children}
      </TranslationsContext.Provider>
    </LanguageContext.Provider>
  )
}

export default Store
