import React, { useContext } from 'react'
import styled from 'styled-components'
import { LanguageContext, TranslationsContext } from '../context/store'

export const InfoBox = styled(({ children, className, data }) => {
  const t = useContext(TranslationsContext)
  const [lang] = useContext(LanguageContext)

  const { facilities, services, price, price2 } = data
  const pairUp = (arr) =>
    arr
      .reduce((res, value, i, a) => {
        if (i % 2 === 0) res.push(a.slice(i, i + 2))
        return res
      }, [])
      .map((x) => (
        <div className='rows' key={x[0].name}>
          <div>
            <img alt='bilde' src={'/' + x[0].icon + '.svg'} />
            {x[0].name}
          </div>
          <div>
            <img alt='bilde' src={'/' + x[1].icon + '.svg'} />
            {x[1] && x[1].name}
          </div>
        </div>
      ))
  const f = pairUp(facilities)
  const s = pairUp(services)

  return (
    <div className={className}>
      <div className='line'>{t.infoBox[lang].with_power}</div>
      <div>
        <h3>
          <span
            style={{ fontSize: '1rem', fontWeight: 'normal', color: 'grey' }}
          >
            {price.currency}
          </span>
          {price.sum}
        </h3>
        <h4>{price.desc}</h4>
      </div>
      <div className='line'>{t.infoBox[lang].without_power}</div>
      <div>
        <h3 className='no-power'>
          <span
            style={{ fontSize: '1rem', fontWeight: 'normal', color: 'grey' }}
          >
            {price2.currency}
          </span>
          {price2.sum}
        </h3>
        <h4>{price2.desc}</h4>
      </div>
      {/* <div className='line'>{t.infoBox[lang].facilities}</div>

      <div className='rows'>{f}</div>

      <div className='line'>{t.infoBox[lang].services}</div>
      <div className='rows'>{s}</div> */}
    </div>
  )
})`
  display: flex;
  position: relative;
  font-family: 'Prompt', sans-serif;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 0.8rem;
  max-width: 400px;
  min-height: 200px;
  margin: 1rem 20vw;
  padding: 1.4rem 0.7rem;

  & h4,
  h3 {
    margin: 0;
    text-align: center;
  }
  & h3 {
    font-size: 3rem;
    &.no-power {
      font-size: 2.5rem;
      color: grey;
    }
  }
  & h4 {
    margin-bottom: 1rem;
    color: #a7a7a7;
    letter-spacing: 0.1rem;
    font-weight: 600;
    font-size: 0.8rem;
  }
  & .rows {
    width: 95%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0.5rem 0.5rem;
    font-size: 0.9rem;
    flex-wrap: wrap;
    & > div {
      color: black;
      letter-spacing: 0.1rem;

      & > img {
        display: inline-block;
        margin: auto 0.5rem;
      }
    }
  }
  & > .line {
    position: relative;
    text-align: center;
    width: 100%;
    color: grey;
    font-size: 0.7rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    margin: 1rem 0;
    &:before,
    :after {
      content: '';
      position: absolute;
      height: 1px;
      width: 25%;
      border: 1px solid #00000026;
      top: 50%;
      bottom: auto;
      left: auto;
      right: auto;
    }
    &:before {
      left: 0rem;
    }
    &:after {
      right: 0rem;
    }
  }
  @media (max-width: 700px) {
    margin: 1rem 5vw;
  }
`
