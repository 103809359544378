export const translation = {
  mainHead: {
    no: 'Ei pærla ved Kvitebergsvatnet',
    de: 'Camp by das wasser',
    en: 'Camp by the water',
  },
  mainPara: {
    no: {
      paragraph: `
Røyrane bobil- og campingvognparkering er ei perla ved Kvitebergsvatnet i Kvinnherad kommune, ytterst i Hardagngerfjorden, med 18 plassar.
        
Her finn ein ro i flott natur. Det er  flott turterreng her og nært til andre kjende turområder.  
Rundt vatnet ligg mange gamle gruver som fortel om gamal historie. Du kan leige kajakk og båt og fiske aure i vatnet.  De kan spela minigolf, ungane har fin leikeplass. De kan  bada i vatnet og kosa dåke i naustet om de vil da.

Me har også sanitæranlegg med vannpåfylling, toalettømming, gråvatn og straum.
        `,
      paraHead: 'Velkommen til Røyrane Bobilparkering',
    },
    de: {
      paragraph: 'placeholder',
      paraHead: 'Welcome to Røyrane Caravan Parking',
    },
    en: {
      paragraph: `
Røyrane caravan parking is a pearl by the lake, Kvitebergsvatnet in Kvinnherad kommune. 
Far out by the fjord Hardagngerfjorden we have 18 spots for campervans, RV's and camping trailers.

Here you will find peaceful surroundings alongside beautiful nature. The area has lovely hiking terrain and is situated close other well known hiking trails.

Around the lake you can explore old historic mining caves, go fishing, have a swim, rent boats/kayaks or play some mini golf. There is also a playground for children. 

We also have a popular boathouse which is a great social spot in the evenings.

Other facilities include; Sanitary-station with water-refill, sewage disposal, grey-water and electricity.
`,
      paraHead: 'Welcome to Røyrane Caravan Parking',
    },
  },
  infoBox: {
    no: {
      el: 'strøm',
      water: 'vann',
      elboat: 'el. båt',
      boat: 'båt',
      services: 'utleie',
      facilities: 'fasiliteter',
      night: 'natt',
      with_power: 'med strøm',
      without_power: 'uten strøm',
    },
    de: {
      el: 'elektrisitet',
      water: 'vann',
      elboat: 'el. båt',
      boat: 'båt',
      services: 'Service',
      facilities: 'filiteten',
      night: 'natt',
      with_power: 'med strøm',
      without_power: 'uten strøm',
    },
    en: {
      el: 'electricity',
      water: 'water',
      elboat: 'el. boat',
      boat: 'boat',
      services: 'services',
      facilities: 'facilities',
      night: 'night',
      with_power: 'with electricity',
      without_power: 'without',
    },
  },
  map: {
    no: 'Åpne kart',
    de: 'Open im karten',
    en: 'Open in Maps',
  },
  contact: {
    no: {
      header: 'Spørsmål ?',
      form: { name: 'navn', email: 'email', message: 'beskjed' },
      call: 'Ring oss',
      social: 'Kontakt',
    },
    de: {
      header: 'Fragen-wagen ?',
      form: { name: 'name', email: 'email', message: 'message' },
      call: 'Call us',
      social: 'Contact',
    },
    en: {
      header: 'Got Question ?',
      form: { name: 'name', email: 'email', message: 'message' },
      call: 'Call us',
      social: 'Contact',
    },
  },
}
