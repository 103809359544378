import styled from "styled-components"

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  & > h3 {
    margin-top: 2rem;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: uppercase;
  }
  & > a {
    font-size: 1.5rem;
    text-decoration: none;
    color: teal;
  }
  & > .social-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0 1rem 1rem 0;
    align-items: center;
    & > a {
      display: flex;
      min-height: 4rem;
      min-width: 4rem;
      border: 1px solid transparent;
      border-radius: 100rem;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat !important;
      background-size: contain !important;
      background-position: centerm !important;
    }
  }
`
